<template>
  <DashboardAdminTemplate>
    <div class="pd-x-5 pd-b-3" v-loading="isLoading">
      <div>
        <el-row>
          <el-col :span="24" class="mg-t-2">
            <router-link to="/refer" class="text-grey-light font-18"
              ><i class="el-icon-arrow-left"></i> ย้อนกลับ</router-link
            >
          </el-col>
          <el-col :span="24">
            <div class="is-flex ai-center js-between">
              <h2 class="mg-b-7">{{ projectName }}</h2>
              <el-button
                type="primary"
                size="default"
                style="width: 130px"
                @click="exportData()"
                >Export</el-button
              >
            </div>
          </el-col>

          <el-col :span="24">
            <p>ผู้เข้าร่วมโครงการ {{ totalData }} คน</p>
          </el-col>
          <el-col :span="24">
            <div class="bordered rounded pd-2 pd-t-4 pd-b-5 mg-b-3">
              <el-form ref="filter" :model="objData" label-position="top">
                <el-row>
                  <el-col :span="8" :offset="0">
                    <el-form-item label="ค้นหา" class="mg-r-5 padding-less">
                      <el-input
                        clearable
                        @input="handleCurrentChange(1)"
                        class="el-input--suffix"
                        v-model="objData.search"
                        placeholder="ค้นหาชื่อ/นามสกุล/เบอร์โทรศัพท์/อีเมล/ชื่อบริษัท/เลขนิติบุคคล"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
              <div style="overflow: hidden"></div>
            </div>
          </el-col>
          <el-col :span="24">
            <el-table
              border
              :data="tableData"
              style="width: calc(100% - 5px)"
              @row-click="modalRef"
              v-loading="loading"
            >
              <template slot="empty">
                <div class="noData">ไม่พบข้อมูลที่ค้นหา</div>
              </template>
              <!-- <el-table-column
                prop="title"
                label="คำนำหน้าชื่อ"
                align="center"
                header-align="center"
              >
              </el-table-column> -->
              <el-table-column
                prop="name"
                label="ชื่อ"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                prop="surname"
                label="นามสกุล"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                prop="mobilephone"
                label="เบอร์โทรศัพท์มือถือ"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                prop="email"
                label="อีเมล"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                prop="companyName"
                label="ชื่อบริษัท"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                prop="corporateNumber"
                label="เลขนิติบุคคล"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                prop="memberOf"
                label="หน่วยงานที่เป็นสมาชิก"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                prop="startJoin"
                label="วันที่สมัครเข้าร่วมโครงการ"
                align="center"
                header-align="center"
              >
                <template slot-scope="scope">
                  {{
                    moment(scope.row.startJoin)
                      .add(543, "year")
                      .format("D MMM yy")
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="allRefs"
                label="โครงการที่เข้าร่วมทั้งหมด"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                prop="status"
                label="สถานะ"
                align="center"
                header-align="center"
                width="125px"
              >
                <template slot-scope="scope">
                  <el-tooltip placement="bottom" :open-delay="800">
                    <div slot="content">{{ scope.row.transactionType }}</div>
                    <span>
                      <i
                        class="fas fa-user-slash text-danger mg-r-7"
                        v-if="
                          scope.row.transactionTypeId == 1 ||
                          scope.row.transactionTypeId == 3
                        "
                      ></i>
                    </span>
                  </el-tooltip>
                  <el-tooltip
                    :content="
                      scope.row.recommendStatus == 1
                        ? 'ประเมินเรียบร้อยแล้ว'
                        : scope.row.descriptionRecommendStatus == 'กำลังประเมิน'
                        ? 'อยู่ระหว่างประเมิน'
                        : ''
                    "
                    :open-delay="500"
                    placement="bottom"
                  >
                    <span
                      class="mg-r-7"
                      v-if="scope.row.recommendStatus == 1"
                      title="ประเมินเรียบร้อยแล้ว"
                    >
                      <i class="el-icon-document-checked text-primary"></i>
                    </span>
                    <span
                      class="mg-r-7"
                      v-if="
                        scope.row.descriptionRecommendStatus == 'กำลังประเมิน'
                      "
                      title="อยู่ระหว่างประเมิน"
                    >
                      <i class="el-icon-document text-warning"></i>
                    </span>
                  </el-tooltip>
                  <span
                    :class="
                      scope.row.status == 0
                        ? 'color-grey-light'
                        : 'color-success'
                    "
                    >{{ getLabelStatusUser(scope.row.status) }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="mg-y-3">
              <div class="block text-center">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage"
                  :page-sizes="[15, 30, 50, 100, 500]"
                  :page-size="15"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalData"
                >
                </el-pagination>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog
      :visible.sync="viewProject"
      v-if="viewProject"
      :destroy-on-close="true"
      width="50%"
    >
      <h1 class="mg-t-0 text-center">
        โครงการที่เข้าร่วมทั้งหมด {{ userData.length }} โครงการ
      </h1>
      <div class="pd-x-1">
        <el-row class="head--table text-center">
          <el-col :span="16">โครงการที่เข้าร่วม</el-col>
          <el-col :span="8">วันที่จัดโครงการ</el-col>
        </el-row>
        <div v-if="userData.length > 0">
          <el-row
            v-for="(data, index) in userData"
            :key="index"
            class="body--table"
          >
            <el-col :span="16">{{ data.project }}</el-col>
            <el-col :span="8" class="text-center">{{
              moment(data.date).add(543, "year").format("D MMM yy")
            }}</el-col>
          </el-row>
        </div>
        <div v-else>
          <el-row class="body--table text-center">
            <el-col :span="24">ไม่พบข้อมูล</el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
  </DashboardAdminTemplate>
</template>
<script>
import DashboardAdminTemplate from "@/template/DashboardAdminTemplate";
import "@/service/Refer";
import { HTTP } from "@/service/axios";
export default {
  components: {
    DashboardAdminTemplate
  },
  computed: {
    statusList() {
      return this.$store.state.statusList;
    }
  },
  data() {
    return {
      viewProject: false,
      isLoading: false,
      loading: false,
      projectName: null,
      widthMax: window.innerWidth,
      objData: {
        search: "",
        refId: this.$route.params.id,
        limit: 15,
        offset: 0
      },
      currentPage: 1,
      tableData: [],
      totalData: 0,
      userData: null
    };
  },
  mounted() {
    this.fetchReferData();
  },
  methods: {
    async funcViewProject ( row )
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      let res = await HTTP.get(`refs/in/${row.userId}`).catch(e => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
        return {
          data: {
            data: e,
            success: false
          }
        };
      });
      return res;
    },
    async modalRef(row, column, event) {
      let data = await this.funcViewProject(row);
      if (data.data.success) {
        this.userData = data.data.obj;
        this.viewProject = true;
      }
    },
    getLabelStatusUser(id) {
      let filter = this.statusList.filter(filter => filter.value == id);
      if (filter.length > 0) {
        return filter[0].label;
      } else {
        return "-";
      }
    },
    async exportData() {
      this.isLoading = true;
      try {
        let res = await this.apiExportRefer(this.token, this.objData.refId);
        if (res.data.success) {
          var name = "_blank";
          var specs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"];

          const url = process.env.VUE_APP_IMAGE_SYSTEM_URL + res.data.obj;
          //const win = window.open(url, specs);
          var createA = document.createElement("a");
          createA.setAttribute("href", url);
          createA.setAttribute("target", "_blank");
          setTimeout(() => {
            createA.click();
          }, 400);
          this.$message({
            type: "success",
            message: "ส่งออกข้อมูลสำเร็จ"
          });
        }
        this.isLoading = false;
      } catch {
        console.log("Function exportData ERROR");
        this.isLoading = false;
      }
    },
    async fetchReferData() {
      this.loading = true;
      try {
        let res = await this.getUserInRefer(this.token, this.objData);
        if (res.data.success) {
          this.tableData = res.data.obj;
          this.totalData = res.data.total;
          this.projectName = res.data.projectName;
        }
        this.loading = false;
      } catch {
        this.loading = false;
        console.log("fetchReferData Catch");
      }
    },
    handleSizeChange(val) {
      this.objData.limit = val;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let start = (this.currentPage - 1) * this.objData.limit;
      this.objData.offset = start;
      this.fetchReferData();
    }
  }
};
</script>
